export  const Colors = {
    1:{
        background:'#DCE1E3',
        text:'black'
    },
    2:{
        background:'black',
        text:'white'
    },
    3:{
        background:'rgb(251 143 30)',
        text:'black'
    },
    4:{
        background:'#591C0B',
        text:'white'
    },
    5:{
        background:'#004E7C',
        text:'#F28888'
    }
};
